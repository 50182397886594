// NOTE: the order of these styles DO matter

.DateInput_fang {
  display: none;
}

.DateRangePicker {
  @apply w-full;
}

.DateRangePickerInput {
  background-color: transparent;
}
.DateInput,
.DateRangePickerInput > *:not(.DateRangePicker_picker) {
  opacity: 0 !important;
  z-index: -1 !important;
}

//
.SingleDatePicker {
  @apply w-full;
}
.SingleDatePickerInput {
  background: transparent;
}
//

.DayPickerNavigation_button__horizontalDefault {
  @apply p-0 flex items-center justify-center w-9 h-9 rounded-full bg-transparent text-neutral-700 dark:text-neutral-200 focus:bg-transparent;
  svg {
    fill: currentColor;
  }
}

.DayPickerNavigation_svg__horizontal {
  @apply w-4 h-4;
}

.CalendarMonth_caption strong {
  @apply font-semibold text-neutral-800 dark:text-neutral-100;
}
.DayPicker_weekHeader {
  @apply text-neutral-500 dark:text-neutral-400;
}
.DateRangePicker_picker,
.SingleDatePicker_picker {
  @apply top-full mt-3 #{!important};
}

.SingleDatePicker_picker,
.DateRangePicker_picker,
.CalendarMonthGrid,
.CalendarMonth {
  background-color: transparent !important;
}
.DayPicker__withBorder,
.DateRangePicker_picker {
  @apply bg-white dark:bg-neutral-800 shadow-xl rounded-3xl #{!important};
}

.CalendarDay__default.CalendarDay__blocked_out_of_range,
.CalendarDay__default.CalendarDay__blocked_out_of_range:active,
.CalendarDay__default.CalendarDay__blocked_out_of_range:hover {
  @apply border border-transparent hover:bg-transparent text-neutral-300 dark:text-neutral-500 cursor-default;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__default {
  @apply w-12 h-12 border border-transparent bg-transparent text-neutral-800 dark:text-neutral-200;
  &:hover {
    @apply bg-neutral-200 border border-neutral-200 dark:bg-neutral-700 dark:border-neutral-700;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  @apply bg-neutral-800 dark:bg-neutral-300 border border-neutral-800 text-neutral-100 dark:text-neutral-900;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span,
.CalendarDay__selected_span {
  @apply bg-neutral-100 dark:bg-neutral-700 border border-neutral-100 dark:border-neutral-700 text-neutral-700;
}

.CalendarDay__selected_span:hover {
  @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100;
}

//
.listingSection__wrap__DayPickerRangeController {
  .DayPicker__withBorder {
    @apply shadow-none mx-auto xl:ml-0  border-neutral-200 dark:border-neutral-700 rounded-xl dark:bg-transparent;
  }
}

//
.nc-SetYourAvailabilityData {
  .CalendarDay__highlighted_calendar {
    @apply bg-neutral-200 dark:bg-neutral-6000 border-neutral-200 dark:border-neutral-6000 text-neutral-700 dark:text-neutral-100 relative;
    &::after {
      content: "";
      width: 1px;
      height: 30px;
      left: 50%;
      transform: rotate(-40deg);
      background-color: black;
      position: absolute;
    }
  }
}


.nc-SetYourAvailabilityData {
  .CalendarDay__blocked_calendar {
    @apply bg-neutral-200 dark:bg-neutral-6000  text-neutral-700 dark:text-neutral-100 relative;
    &::after {
      content: "Booked";
      width: 0px;
      font-size: 9px;
      font-weight: 600;
      bottom: 0%;
      left: 20%;
      background-color: black;
      position: absolute;
    
      transform-origin: center center;
      

    }
  }
}

.nc-SetYourAvailabilityData {
  .CalendarDay__blocked_calendar.CalendarDay__highlighted_calendar {
    @apply bg-neutral-200 dark:bg-neutral-6000  text-neutral-700 dark:text-neutral-100 relative;
    &::after {
      content: "Booked";
      width: 0px;
      font-size: 9px;
      font-weight: 600;
      bottom: 0%;
      left: 20%;
      background-color: black;
      position: absolute;
      transform: rotate(0deg);
      height: 25px;
      transform-origin: center center;
      

    }
  }
}


  .dt-wrapper div
  {
    overflow: visible;
  }

  .z-5{
    z-index: 5;
  }

  .top-88-px{
    top: 88px;
  }


  #single-entry-content img{
    @apply rounded-lg
  }





  html {
    transition: all 0.4s ease-in-out;
  }


  #root > div.bg-white.text-base.dark\:bg-neutral-900.text-neutral-900.dark\:text-neutral-200 > div.h-full.flex > div.flex.flex-col.min-w-0.flex-1.overflow-hidden.sm\:overflow-visible > div.flex-1.relative.z-5.flex.overflow-hidden.sm\:overflow-visible > main > div > article > div:nth-child(11) > div > div > div.mt-6 > dl > div > div > div {
    visibility: visible !important;
    min-height: 359px;
  }
  

  .app__messenger{
    @apply md:min-h-screen ;
    right: 0px !important;
    min-width: 260px !important;
    min-height: 380px !important;

    
  }




 html{
  scroll-behavior: smooth;
 }

  .typing-cursor::after{
    content: "|";
    font-weight: 400;
    display: inline;
    animation: blink 1s infinite;
    color: #797D85;
    animation: blink-animation 0.2s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.2s steps(5, start) infinite;
    transition: 0.3s all ease;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }



  ._3sXmF {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: background 0.2s ease;
    min-height: 28px;
  }
  
  ._3sXmF:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4);
  }
  
  ._ygkSb {
    overflow-y: auto;
    transition: background 0.2s ease;
  }
  
  /* Width */
  ._ygkSb::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ._ygkSb::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ._ygkSb::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  ._ygkSb::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  ._ygkSb::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  
  ._ygkSb:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4);
  }

  div.ant-table-scroll-horizontal {
    overflow: auto;
    transition: background 0.2s ease;
  }
  
  /* Width */
  div.ant-table-scroll-horizontal::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  div.ant-table-scroll-horizontal::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  div.ant-table-scroll-horizontal::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  div.ant-table-scroll-horizontal::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  div.ant-table-scroll-horizontal::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  
  div.ant-table-scroll-horizontal:hover {
    cursor: pointer;
    background: rgba(220, 220, 220, 0.4);
  }
  
  ._1EEDX {
    width: 100%;
    padding: 5px;
    display: block;
    margin: 3px 0;
    scrollbar-width: thin;
  }

  
  html::-webkit-scrollbar{
    width: 8px;
  }

  html::-webkit-scrollbar-track{
    background: #f1f1f1;
  }

  /* Handle */
  html::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  html::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  html::-webkit-scrollbar-thumb:active {
    background: #666;
  }

  div::-webkit-scrollbar{
    width: 5px;
  }

  div::-webkit-scrollbar-track{
    background: #f1f1f1;
  }

  /* Handle */
  div::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  div::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  div::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  ul::-webkit-scrollbar{
    height: 3px;
  }

  ul::-webkit-scrollbar-track{
    background: #f1f1f1;
  }

  /* Handle */
  ul::-webkit-scrollbar-thumb {
    @apply bg-primary-700

  }
  /* Handle on hover */
  ul::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  ul::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  

  nav::-webkit-scrollbar{
    width: 8px;
    border-radius: 5px;
  }

  nav::-webkit-scrollbar-track{
    background: #f1f1f1;
  }

  /* Handle */
  nav::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  nav::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-700
  }
  /* Handle on drag */
  nav::-webkit-scrollbar-thumb:active {
    @apply bg-primary-50
  }
  

  html::-webkit-scrollbar{
    width: 5px;
  }







  
  /* Width */
  ._1EEDX::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ._1EEDX::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ._1EEDX::-webkit-scrollbar-thumb {
    background: #ccc;
  }
  /* Handle on hover */
  ._1EEDX::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  /* Handle on drag */
  ._1EEDX::-webkit-scrollbar-thumb:active {
    background: #666;
  }
  
  ._3qwMT {
    color: #999;
  }
  
  ._3F-ST {
    display: inline !important;
  }
  
  ._38077 {
    cursor: auto !important;
  }
  
  ._38077:hover {
    cursor: auto !important;
  }
  
  ._2_49w {
    display: flex;
  }
  
  ._PG2W0 {
    background-color: #fff;
    color: black;
    border: 0;
    outline: none;
    min-width: 28px;
  }
  
  ._PG2W0:focus {
    border: 0;
    outline: none;
  }


  .btn-white{
    @apply inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm
    font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 
    focus:ring-offset-gray-50 focus:ring-primary-500 ml-2 
  }

  .btn-white:disabled{
    @apply shadow-none text-gray-300 hover:bg-white 
  }
  

  .rdt_TableRow{
    @apply border border-gray-100 rounded-md border-opacity-40 
  }

  .rdt_TableHeader{
    
  }

  //data table navbar foo2
  .sc-hHTYSt{
    @apply overflow-auto
  }

  .sc-dJjYzT{
    @apply overflow-auto

  }











  //invoice CSS
  .receipt-content .logo a:hover {
    text-decoration: none;
    color: #7793C4; 
  }
  
  .receipt-content .invoice-wrapper {
    background: #FFF;
    border: 1px solid #CDD3E2;
    box-shadow: 0px 0px 1px #CCC;
    padding: 40px 40px 60px;
    // margin-top: 40px;
    border-radius: 4px; 
  }
  
  .receipt-content .invoice-wrapper .payment-details span {
    // color: #A9B0BB;
    display: block; 
  }
  .receipt-content .invoice-wrapper .payment-details a {
    display: inline-block;
    margin-top: 5px; 
  }
  
  .receipt-content .invoice-wrapper .line-items .print a {
    display: inline-block;
    border: 1px solid #9CB5D6;
    padding: 13px 13px;
    border-radius: 5px;
    color: #708DC0;
    font-size: 13px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; 
  }
  
  .receipt-content .invoice-wrapper .line-items .print a:hover {
    text-decoration: none;
    border-color: #333;
    color: #333; 
  }
  
  .receipt-content {
    // background: #ECEEF4; 
    padding-top: 20px;
  }
  @media (min-width: 1200px) {
    .receipt-content .container {width: 900px; } 
  }
  
  .receipt-content .logo {
    text-align: center;
    margin-top: 50px; 
  }
  
  .receipt-content .logo a {
    font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
    font-size: 36px;
    letter-spacing: .1px;
    color: #555;
    font-weight: 300;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear; 
  }
  
  .receipt-content .invoice-wrapper .intro {
    line-height: 25px;
    color: #444; 
  }
  
  .receipt-content .invoice-wrapper .payment-info {
    margin-top: 25px;
    padding-top: 15px; 
  }
  
  .receipt-content .invoice-wrapper .payment-info span {
    // color: #A9B0BB; 
  }
  
  .receipt-content .invoice-wrapper .payment-info strong {
    display: block;
    color: #444;
    margin-top: 3px; 
  }
  
  @media (max-width: 767px) {
    .receipt-content .invoice-wrapper .payment-info .text-right {
    text-align: left;
    margin-top: 20px; } 
  }
  .receipt-content .invoice-wrapper .payment-details {
    border-top: 2px solid #EBECEE;
    margin-top: 30px;
    padding-top: 20px;
    line-height: 22px; 
  }
  
  
  @media (max-width: 767px) {
    .receipt-content .invoice-wrapper .payment-details .text-right {
    text-align: left;
    margin-top: 20px; } 
  }
  .receipt-content .invoice-wrapper .line-items {
    margin-top: 40px; 
  }
  .receipt-content .invoice-wrapper .line-items .headers {
    color: #A9B0BB;
    font-size: 13px;
    letter-spacing: .3px;
    border-bottom: 2px solid #EBECEE;
    padding-bottom: 4px; 
  }
  .receipt-content .invoice-wrapper .line-items .items {
    margin-top: 8px;
    border-bottom: 2px solid #EBECEE;
    padding-bottom: 8px; 
  }
  .receipt-content .invoice-wrapper .line-items .items .item {
    padding: 10px 0;
    color: #696969;
    font-size: 15px; 
  }
  @media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .items .item {
    font-size: 13px; } 
  }
  .receipt-content .invoice-wrapper .line-items .items .item .amount {
    letter-spacing: 0.1px;
    color: #84868A;
    font-size: 16px;
   }
  @media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .items .item .amount {
    font-size: 13px; } 
  }
  
  .receipt-content .invoice-wrapper .line-items .total {
    margin-top: 30px; 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .extra-notes {
    float: left;
    width: 40%;
    text-align: left;
    font-size: 13px;
    color: #7A7A7A;
    line-height: 20px; 
  }
  
  @media (max-width: 767px) {
    .receipt-content .invoice-wrapper .line-items .total .extra-notes {
    width: 100%;
    margin-bottom: 30px;
    float: none; } 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .extra-notes strong {
    display: block;
    margin-bottom: 5px;
    color: #454545; 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .field {
    margin-bottom: 7px;
    font-size: 14px;
    color: #555; 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .field.grand-total {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500; 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .field.grand-total span {
    color: #20A720;
    font-size: 16px; 
  }
  
  .receipt-content .invoice-wrapper .line-items .total .field span {
    display: inline-block;
    margin-left: 20px;
    min-width: 85px;
    color: #84868A;
    font-size: 15px; 
  }
  
  .receipt-content .invoice-wrapper .line-items .print {
    margin-top: 50px;
    text-align: center; 
  }
  
  
  
  .receipt-content .invoice-wrapper .line-items .print a i {
    margin-right: 3px;
    font-size: 14px; 
  }
  
  .receipt-content .footer {
    margin-top: 40px;
    margin-bottom: 110px;
    text-align: center;
    font-size: 12px;
    color: #969CAD; 
  }     
  
